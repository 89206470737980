import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type DeleteformState = DeepReadonly<{
  code: string
  agree: boolean
  havesubmit: boolean
}>

const initialState: DeleteformState = {
  code: "",
  agree: false,
  havesubmit: false
}

const SETCODE = 'view/deleteform/SETCODE'
const TOGGLEAGREE = 'view/deleteform/TOGGLEAGREE'
const SETHAVESUBMIT = 'view/deleteform/SETHAVESUBMIT'
const RESET = 'view/deleteform/RESET'

export const setcode = createAction(SETCODE)<string>()
export const toggleagree = createAction(TOGGLEAGREE)()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const reset = createAction(RESET)()

const actions = { setcode, toggleagree, sethavesubmit, reset }
export type DeleteformAction = ActionType<typeof actions>

export default createReducer<DeleteformState, DeleteformAction>(initialState, {
  [SETCODE]: (state, action) => Object.assign({}, state, { code: action.payload }),
  [TOGGLEAGREE]: (state) => Object.assign({}, state, { agree: !state.agree }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [RESET]: () => initialState
})
