import axios from 'axios'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { initialState, RequestState } from '../utils'
import { set } from './view'

const REQUEST = 'meal/fetch/REQUEST'
const SUCCESS = 'meal/fetch/SUCCESS'
const FAILURE = 'meal/fetch/FAILURE'

export const mealfetch = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { mealfetch }
export type MealFetchAction = ActionType<typeof actions>;

const MEAL_API_URL = "https://meal-api.sshs-pebble.dev/"

export const mealfetchEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(mealfetch.request)),
  mergeMap(action => from(axios.get(MEAL_API_URL)).pipe(
    mergeMap(response => {
      let data = response.data[new Date().getDate()]
      for(let key in data) {
        data[key] = data[key].split('\n')
      }
      return of(set(data), mealfetch.success(true))
    }),
    catchError(e => of(mealfetch.failure(e)))
  ))
)

export default createReducer<RequestState, MealFetchAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
