import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type CodeState = DeepReadonly<{
  code?: string
  name?: string
  grade?: number
  class?: number
  number?: number
  subjects?: number[]
  classes?: number[]
}>

const initialState: CodeState = {}

const SETALL = 'user/SETALL'
const SETCODE = 'user/SETCODE'
const ADDSUBJECT = 'user/ADDSUBJECT'
const DELSUBJECT = 'user/DELSUBJECT'
const RESET = 'user/RESET'

export const setall = createAction(SETALL)<CodeState>()
export const setcode = createAction(SETCODE)<string>()
export const addsubject = createAction(ADDSUBJECT)<{subject: number, class: number}>()
export const delsubject = createAction(DELSUBJECT)<{subject: number, class: number}>()
export const reset = createAction(RESET)()

const actions = { setall, setcode, addsubject, delsubject, reset }
export type UserAction = ActionType<typeof actions>;

export default createReducer<CodeState, ActionType<typeof actions>>(initialState, {
  [SETALL]: (state, action) => Object.assign({}, state, action.payload),
  [SETCODE]: (state, action) => Object.assign({}, state, { code: action.payload }),
  [ADDSUBJECT]: (state, action) => Object.assign({}, state, {
    subjects: [...state.subjects!, action.payload.subject],
    classes: [...state.classes!, action.payload.class]
  }),
  [DELSUBJECT]: (state, action) => Object.assign({}, state, {
    subjects: state.subjects!.filter((_, i) =>
      state.subjects![i] === action.payload.subject
      && state.classes![i] === action.payload.class),
    classes: state.classes!.filter((_, i) =>
      state.subjects![i] === action.payload.subject
      && state.classes![i] === action.payload.class)
  }),
  [RESET]: () => initialState
})
