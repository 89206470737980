import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { initialState, instance, RequestState } from '../utils'
import { setsubjectsinfo } from './view'

const REQUEST = 'timetable/getsubjects/REQUEST'
const SUCCESS = 'timetable/getsubjects/SUCCESS'
const FAILURE = 'timetable/getsubjects/FAILURE'

export const getsubjects = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { getsubjects }
export type GetsubjectsAction = ActionType<typeof actions>;

export const getsubjectsEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(getsubjects.request)),
  mergeMap(action => from(instance.get('subjects')).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      return of(setsubjectsinfo(Object.values(response.data.data).map((elem: any) => {
        delete elem._id
        return elem
      })), getsubjects.success(true))
    }),
    catchError(e => of(getsubjects.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
