import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { StateType } from 'typesafe-actions'
import view, { MealViewAction } from './view'
import fetch, { MealFetchAction, mealfetchEpic } from './fetch'

const mealReducer = combineReducers({
  view,
  fetch
})

export const mealEpic = combineEpics(
  mealfetchEpic
)

export default mealReducer
export type MealState = StateType<typeof mealReducer>
export type MealAction = MealFetchAction | MealViewAction