import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

type SignupformState = DeepReadonly<{
  name: string
  code: string
  grade?: number
  class?: number
  number?: number
  password: string
  passcheck: string
  havesubmit: boolean
}>

const initialState: SignupformState = {
  name: "",
  code: "",
  password: "",
  passcheck: "",
  havesubmit: false
};

const SETNAME = 'view/signupform/SETNAME'
const SETCODE = 'view/signupform/SETCODE'
const SETGRADE = 'view/signupform/SETGRADE'
const SETCLASS = 'view/signupform/SETCLASS'
const SETNUM = 'view/signupform/SETNUM'
const SETPASSWORD = 'view/signupform/SETPASSWORD'
const SETPASSCHECK = 'view/signupform/SETPASSCHECK'
const SETHAVESUBMIT = 'view/signupform/SETHAVESUBMIT'
const RESET = 'view/signupform/RESET'

export const setname = createAction(SETNAME)<string>()
export const setcode = createAction(SETCODE)<string>()
export const setgrade = createAction(SETGRADE)<number | undefined>()
export const setclass = createAction(SETCLASS)<number | undefined>()
export const setnum = createAction(SETNUM)<number | undefined>()
export const setpassword = createAction(SETPASSWORD)<string>()
export const setpasscheck = createAction(SETPASSCHECK)<string>()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const reset = createAction(RESET)()

const actions = { setname, setcode, setgrade, setclass, setnum, setpassword, setpasscheck, sethavesubmit, reset }
export type SignupformAction = ActionType<typeof actions>;

export default createReducer<SignupformState, SignupformAction>(initialState, {
  [SETNAME]: (state, action) => Object.assign({}, state, { name: action.payload }),
  [SETCODE]: (state, action) => Object.assign({}, state, { code: action.payload }),
  [SETGRADE]: (state, action) => Object.assign({}, state, { grade: action.payload }),
  [SETCLASS]: (state, action) => Object.assign({}, state, { class: action.payload }),
  [SETNUM]: (state, action) => Object.assign({}, state, { number: action.payload }),
  [SETPASSWORD]: (state, action) => Object.assign({}, state, { password: action.payload }),
  [SETPASSCHECK]: (state, action) => Object.assign({}, state, { passcheck: action.payload }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [RESET]: () => initialState
})
