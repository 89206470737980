import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type LoginformState = DeepReadonly<{
  code: string
  password: string
  havesubmit: boolean
}>

const initialState: LoginformState = {
  code: "",
  password: "",
  havesubmit: false
}

const SETCODE = 'view/loginform/SETCODE'
const SETPASSWORD = 'view/loginform/SETPASSWORD'
const SETHAVESUBMIT = 'view/loginform/SETHAVESUBMIT'
const RESET = 'view/loginform/RESET'

export const setcode = createAction(SETCODE)<string>()
export const setpassword = createAction(SETPASSWORD)<string>()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const reset = createAction(RESET)()

const actions = { setcode, setpassword, sethavesubmit, reset }
export type LoginformAction = ActionType<typeof actions>;

export default createReducer<LoginformState, LoginformAction>(initialState, {
  [SETCODE]: (state, action) => Object.assign({}, state, { code: action.payload }),
  [SETPASSWORD]: (state, action) => Object.assign({}, state, { password: action.payload }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [RESET]: () => initialState
})
