import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import configureStore from './redux/store';
import './styles/globals.css';

const store = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={store.__persistor!}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
