import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

export type SnackViewState = DeepReadonly<{
  snack?: string[],
}>

const SET = 'snack/view/SET'

export const set = createAction(SET)<SnackViewState>()

const actions = { set }
export type SnackViewAction = ActionType<typeof actions>;

export default createReducer<SnackViewState, SnackViewAction>({}, {
  [SET]: (state, action) => action.payload
})
