import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { reset } from '../user'
import { initialState, instance, RequestState } from '../utils'

const REQUEST = 'auth/delete/REQUEST'
const SUCCESS = 'auth/delete/SUCCESS'
const FAILURE = 'auth/delete/FAILURE'

export const delete_ = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { delete_ }
export type DeleteAction = ActionType<typeof actions>;

export const deleteEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(delete_.request)),
  mergeMap(action => from(instance.delete('auth/user')).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")
      
      return of(delete_.success(true), reset())
    }),
    catchError(e => of(delete_.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})