import React, { useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Button, Checkbox, Form, Icon, Input, Message } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { delete_ } from '../redux/reducer/auth/delete'
import { reset, setcode, sethavesubmit, toggleagree } from '../redux/reducer/view/deleteform'

export const DeleteForm: React.FC = () => {
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const { code, agree, havesubmit } = useSelector((state: RootState) => state.view.deleteform)
  const code_saved = useSelector((state: RootState) => state.user.code)
  const { loading, error } = useSelector((state: RootState) => state.auth.delete)

  const code_p = /[1-2]\d[0-1]\d{2}/

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(sethavesubmit())

    const formelements = event.currentTarget.elements
    for (let i = 0; i < formelements.length; i++) {
      if (!formelements[i].hasAttribute('name')) continue

      const element = formelements[i] as HTMLInputElement
      dispatch({
        type: `view/deleteform/SET${element.name.toUpperCase()}`,
        payload: element.value
      })
    }

    const { code } = store.getState().view.deleteform as RootState['view']['deleteform']

    if (code_saved !== code) return;

    console.log("Validation Success")

    dispatch(delete_.request())
  }

  return (
    <Form warning onSubmit={onSubmit} error={error !== undefined}>
      <p>
        이 행동은 <b>돌이킬 수 없습니다.</b> 이는 사용자의 계정, 정보, 시간표 등이 <b>영구히</b> 삭제됨을 의미합니다.
      </p>
      <p>
        아래에 교번을 입력하여 승인하세요.
      </p>
      <Form.Field
        control={Input} name="code"
        label="교번" placeholder="교번"
        type="text" maxLength={5}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
          dispatch(setcode(value));
        }}
        error={havesubmit && !code_p.test(code)}
      />
      <Form.Field
        control={Checkbox} name="agree"
        label="위험을 인지하였으며, 회원 탈퇴에 동의합니다."
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          dispatch(toggleagree())
        }}
        error={havesubmit && !agree}
      />
      <Message
        error
        header='회원탈퇴 실패'
        content={error !== undefined ? error!.message : ""}
      />
      <Form.Field>
        <Button fluid basic color='red' type='submit' loading={loading}>
          <Icon name="user delete" />
          회원 탈퇴
        </Button>
      </Form.Field>
    </Form>
  )
}