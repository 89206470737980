import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ModifyView } from '../components/ModifyView';
import { withBasicView } from '../components/withBasicView';
import { setactiveitem } from '../redux/reducer/view/navbar';

export default function Modify() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setactiveitem(''))
  }, [dispatch])
  
  const HomeWithBasicView = withBasicView(ModifyView);

  return <HomeWithBasicView />;
}