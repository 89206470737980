import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { composeAltCode } from '../../../helper'
import { initialState, instance, RequestState } from '../utils'
import { setresult } from '../view/findform'

const REQUEST = 'timetable/find/REQUEST'
const SUCCESS = 'timetable/find/SUCCESS'
const FAILURE = 'timetable/find/FAILURE'

export type FindRequestType = {
  name: string
}

export const find = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<FindRequestType, boolean, Error>()

const actions = { find }
export type FindAction = ActionType<typeof actions>;

export const findEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(find.request)),
  mergeMap(action => from(instance.get('auth/user', { params: action.payload })).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")
      
      let data = response.data.data.map((elem: any) => {
        return {
          code: (elem.code as string),
          name: (elem.name as string),
          altcode: composeAltCode((elem.grade as number), (elem.class as number), (elem.number as number))
        }
      })

      return of(setresult(data), find.success(true))
    }),
    catchError(e => of(find.failure(e)))
  ))
)

export default createReducer<RequestState, FindAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
