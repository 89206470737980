import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { initialState, instance, RequestState } from '../utils'
import { setsharedfreearr } from './view'

const REQUEST = 'timetable/getsharedfreearr/REQUEST'
const SUCCESS = 'timetable/getsharedfreearr/SUCCESS'
const FAILURE = 'timetable/getsharedfreearr/FAILURE'

export type GetsharedfreearrRequestType = {
  code: string
}

export const getsharedfreearr = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<GetsharedfreearrRequestType, boolean, Error>()

const actions = { getsharedfreearr }
export type GetsharedfreearrAction = ActionType<typeof actions>;

export const getsharedfreearrEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => 
  action$.pipe(
  filter(isActionOf(getsharedfreearr.request)),
  mergeMap(action => from(instance.get(`gyeopgonggang?code=${action.payload.code}`)).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      let sharedfreearr = [[false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false]]
      
      for(const line of response.data.data) {
        for(const num of line){
          if(num === 1) {
            sharedfreearr[response.data.data.indexOf(line)][line.indexOf(num)] = true
          }
        }
      }

      return of(setsharedfreearr(sharedfreearr), getsharedfreearr.success(true))
    }),
    catchError(e => of(getsharedfreearr.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
