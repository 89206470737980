import { combineReducers } from 'redux'
import { Epic, combineEpics } from 'redux-observable'
import { catchError } from 'rxjs'
import { StateType } from 'typesafe-actions'
import auth, { AuthAction, authEpic } from './auth'
import { MealAction, mealEpic } from './meal'
import timetable, { TimeTableAction, timetableEpic } from './timetable'
import user, { UserAction } from './user'
import view, { ViewAction } from './view'
import meal from './meal'
import snack from './snack'
import { SnackAction, snackEpic } from './snack'

const rootReducer = combineReducers({
  view,
  auth,
  timetable,
  user,
  meal,
  snack
})

export const rootEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store$,
  dependencies
) => combineEpics(
  authEpic,
  timetableEpic,
  mealEpic,
  snackEpic
)(action$, store$, dependencies).pipe(
  catchError((error, source) => {
    console.error(error)
    console.log('Epic Error')
    return source
  })
)

export default rootReducer
export type RootState = StateType<typeof rootReducer>
export type RootAction = AuthAction | TimeTableAction | UserAction | ViewAction | MealAction | SnackAction