import React, { useEffect } from 'react'
import { batch, useDispatch, useSelector, useStore } from 'react-redux'
import { Button, Form, Input, List, Message } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { find } from '../redux/reducer/timetable/find'
import { gethighlight } from '../redux/reducer/timetable/gethighlight'
import { reset, setname, sethavesubmit } from '../redux/reducer/view/findform'
import { getsharedfreearr } from '../redux/reducer/timetable/getsharedfree'

const name_p = /[가-힣|A-F]+/

export const FindForm: React.FC = () => {
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const { name, result, havesubmit } = useSelector((state: RootState) => state.view.findform)
  const { loading, error } = useSelector((state: RootState) => state.timetable.gethighlight)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(sethavesubmit())

    const formelements = event.currentTarget.elements
    for (let i = 0; i < formelements.length; i++) {
      if (!formelements[i].hasAttribute('name')) continue

      const element = formelements[i] as HTMLInputElement
      dispatch({
        type: `view/findform/SET${element.name.toUpperCase()}`,
        payload: element.value
      })
    }

    const { name } = store.getState().view.findform as RootState['view']['findform']

    if (!name_p.test(name)) return;

    dispatch(find.request({ name }))
  }

  return (
    <div>
      <Form onSubmit={onSubmit} error={havesubmit && error !== undefined}>
        <Form.Field
          control={Input} name="name"
          placeholder="이름"
          type="text" maxLength={5}
          onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
            dispatch(setname(value))
          }}
          action={<Button type='submit' icon='search' loading={loading} />}
          error={havesubmit && !name_p.test(name)}
        />
        <Message
          error
          header='겹강 찾기 실패'
          content={error !== undefined ? error!.message : ""}
        />
      </Form>
      {havesubmit &&
        (result.length !== 0 ?
          <List>
            {result.map(({ code, name, altcode }) => (
              <List.Item key={code}>
                <List.Content floated='right'>
                  <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    batch(() => {
                      dispatch(gethighlight.request({ code }))
                      dispatch(getsharedfreearr.request({ code }))
                    })
                  }}>
                    겹강 찾기
                  </Button>
                </List.Content>
                <List.Content verticalAlign="middle">
                  {altcode} {name}
                </List.Content>
              </List.Item>))}
          </List> :
          <div className="w-full text-center" style={{ color: "rgba(40, 40, 40, 0.3)" }}>
            검색 결과가 없어요!
          </div>)
      }
    </div>
  )
}