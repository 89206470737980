import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { composeAltCode } from '../../../helper'
import { initialState, instance, RequestState } from '../utils'
import { Period } from './utils'
import { sethighlight, setuserinfo } from './view'

const REQUEST = 'timetable/gethighlight/REQUEST'
const SUCCESS = 'timetable/gethighlight/SUCCESS'
const FAILURE = 'timetable/gethighlight/FAILURE'

export type GethighlightRequestType = {
  code: string
}

export const gethighlight = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<GethighlightRequestType, boolean, Error>()

const actions = { gethighlight }
export type GethighlightAction = ActionType<typeof actions>;

export const gethighlightEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(gethighlight.request)),
  mergeMap(action => from(instance.get('auth/user', { params: action.payload })).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      const classesinfo = state$.value.timetable.view.classesinfo

      let subjectsthere: number[] = response.data.data.subjects
      let classesthere: number[] = response.data.data.classes
      const zippedthere: { subject: number, class: number }[] = subjectsthere.map((elem, i) => {
        return { subject: elem, class: classesthere[i] }
      })

      let highlight = [[false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false], [false, false, false, false, false, false, false]]
      classesinfo.filter((elem) =>
        zippedthere.find(({ subject, class: class_ }) =>
          subject === elem.subject && class_ === elem.class
        )
      ).reduce((acc: Period[], cur) => [...acc, ...cur.periods], []).forEach(({ day, time }) => {
        highlight[day][time - 1] = true
      })

      let { name, grade, class: class_, number: num }: { name: string, grade: number, class: number, number: number } = response.data.data
      let altcode = composeAltCode(grade, class_, num)
      return of(sethighlight(highlight), setuserinfo({ altcode, name }), gethighlight.success(true))
    }),
    catchError(e => of(gethighlight.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
