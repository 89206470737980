import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type FindformState = DeepReadonly<{
  name: string
  result: {
    name: string,
    altcode: string,
    code: string
  }[]
  havesubmit: boolean
}>

const initialState: FindformState = {
  name: "",
  result: [],
  havesubmit: false
}

const SETNAME = 'view/findform/SETNAME'
const SETHAVESUBMIT = 'view/findform/SETHAVESUBMIT'
const SETRESULT = 'view/findform/SETRESULT'
const RESET = 'view/findform/RESET'

export const setname = createAction(SETNAME)<string>()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const setresult = createAction(SETRESULT)<{ name: string, altcode: string, code: string }[]>()
export const reset = createAction(RESET)()

const actions = { setname, sethavesubmit, setresult, reset }
export type FindformAction = ActionType<typeof actions>

export default createReducer<FindformState, FindformAction>(initialState, {
  [SETNAME]: (state, action) => Object.assign({}, state, { name: action.payload }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [SETRESULT]: (state, action) => Object.assign({}, state, { result: action.payload }),
  [RESET]: () => initialState
})
