import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Header, Loader, Message, Table } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { mealfetch } from '../redux/reducer/meal/fetch'
import { snackfetch } from '../redux/reducer/snack/fetch'

export const HomeView: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory();

  const meal = useSelector((state: RootState) => state.meal.view);
  const snack = useSelector((state: RootState) => state.snack.view);
  const { loading: mealLoading, success: mealSuccess } = useSelector((state: RootState) => state.meal.fetch);
  const { loading: snackLoading, success: snackSuccess } = useSelector((state: RootState) => state.snack.fetch);
  const loading = mealLoading || snackLoading ; const success = mealSuccess || snackSuccess

  useEffect(() => {
    dispatch(mealfetch.request());
    dispatch(snackfetch.request())
  }, [dispatch])

  return (
    <div>
      <Header as='h1'>
        환영합니다!
        <Header.Subheader>
          겹강은 서울과학고등학교 재학생 여러분에게 겹강을 찾아주는 기능을 필두로,<br />
          과목 및 시간표 데이터를 이용한 여러 가지 편리한 기능을 제공하는 것을 목적으로 한 서비스입니다.
        </Header.Subheader>
      </Header>

      <Message warning>
        <Message.Header>겹강 서비스 이용에 관하여</Message.Header>
        <p>제공되는 정보 중 잘못된 정보가 존재할 수 있음을 알려드립니다. 본 웹사이트는 학생들의 편의를 위해 제작되었으며, 학생회 및 정보부는 잘못된 정보에 따른 피해에 대한 책임을 지지 않습니다.<br />
          또한, 학생들이 자신의 시간표를 수정하거나 계정을 삭제하는 경우에 실제와 상이한 결과가 나올 수 있다는 점 참고 부탁드립니다.
        </p>
        <p>본인의 정확한 시간표는 담임 선생님께 직접 확인하시기 바랍니다. 잘못된 정보가 있는 경우 제보해주시면 감사하겠습니다.</p>
      </Message>

      <Header as='h3'>
        제공되는 기능
      </Header>
      {/*<Message header='시간표 관리' content='자신의 시간표를 관리하고, 과목을 추가/삭제할 수 있습니다.' />*/}
      <div onClick={() => history.push('/find')}>
        <Message
          header='겹강 찾기' content='교번을 입력하여 겹강을 찾고, 자신과 최대 겹강인 상위 몇 명의 학생을 찾을 수 있습니다.'
        />
      </div>

      <Header as='h3'>
        추가 예정 기능
      </Header>
      <Message header='기출문제 다운로드' content='자신의 시간표를 바탕으로 기출문제를 일괄 다운로드할 수 있게 제공할 계획입니다.' />

      <Header as='h3'>
        오늘의 급식
      </Header>
      {
        !loading && success ?
          <Table textAlign="center">
            <Table.Body>
              <Table.Row>
                <Table.Cell className="font-bold">아침</Table.Cell>
                {meal.morning === undefined ? <Table.Cell disabled content={"급식이 없는 것 같아요!"} /> :
                  <Table.Cell>
                    {meal.morning!.map((food, index) => <div key={index}>{food}</div>)}
                  </Table.Cell>
                }
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-bold">점심</Table.Cell>
                {meal.lunch === undefined ? <Table.Cell disabled content={"급식이 없는 것 같아요!"} /> :
                  <Table.Cell>
                    {meal.lunch!.map((food, index) => <div key={index}>{food}</div>)}
                  </Table.Cell>
                }
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-bold">저녁</Table.Cell>
                {meal.dinner === undefined ? <Table.Cell disabled content={"급식이 없는 것 같아요!"} /> :
                  <Table.Cell>
                    {meal.dinner!.map((food, index) => <div key={index}>{food}</div>)}
                  </Table.Cell>
                }
              </Table.Row>
              <Table.Row>
                <Table.Cell className="font-bold">간식</Table.Cell>
                {snack.snack === undefined ? <Table.Cell disabled content={"간식이 없는 것 같아요!"} /> :
                  <Table.Cell>
                    {snack.snack!.map((food, index) => <div key={index}>{food}</div>)}
                  </Table.Cell>
                }
              </Table.Row>
            </Table.Body>
          </Table> :
          <Loader active inline='centered' content="급식 정보를 가져오고 있습니다." />
      }
    </div>
  )
}