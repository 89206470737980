import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, Menu, Segment } from 'semantic-ui-react'
import { composeAltCode } from '../helper'
import { RootState } from '../redux/reducer'
import { fetch } from '../redux/reducer/auth/fetch'
import { logout } from '../redux/reducer/auth/logout'

export const Navbar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { code, name, grade, class: class_, number: num } = useSelector((state: RootState) => state.user)
  const { loading, success } = useSelector((state: RootState) => state.auth.fetch)

  useEffect(() => {
    if(code !== undefined)
      dispatch(fetch.request({}))
  }, [dispatch, code])

  const activeitem = useSelector((state: RootState) => state.view.navbar.activeitem)

  return (
    <Segment inverted style={{ borderRadius: 0 }} className='w-100'>
      <Menu inverted stackable secondary>
        <Menu.Item header>
          Gyeopgang
        </Menu.Item>
        <Link to='/'>
          <Menu.Item
            name='홈'
            active={activeitem === 'Home'}
            className='cursor-pointer'
          />
        </Link>
        {/*{code !== undefined &&
          <Link to='/subject'>
            <Menu.Item
              name='과목 관리'
              active={activeitem === 'Subject'}
              className='cursor-pointer'
            />
          </Link>
        }*/}
        {code !== undefined &&
          <Link to='/find'>
            <Menu.Item
              name='겹강 찾기'
              active={activeitem === 'Find'}
              className='cursor-pointer'
            />
          </Link>
        }
        <Menu.Menu position='right'>
          {code === undefined ?
            <Link to='/login'>
              <Menu.Item
                name='로그인'
                className='cursor-pointer'
              />
            </Link> :
            loading || !success || name === undefined ?
              <Dropdown item loading></Dropdown> :
              <Dropdown item text={`${composeAltCode(grade!, class_!, num!)} ${name}`}>
                <Dropdown.Menu>
                  <Dropdown.Item icon='pencil' text='회원정보 수정'
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      history.push('/modify')
                    }}
                  />
                  <Dropdown.Item icon='sign out' text='로그아웃'
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                      dispatch(logout.request())
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
          }
        </Menu.Menu>

      </Menu>
    </Segment >
  )
}
