import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { resetall } from '../timetable/view'
import { reset, setall } from '../user'
import { initialState, instance, RequestState } from '../utils'
import { greset } from '../view/gyeopgang'

const REQUEST = 'auth/fetch/REQUEST'
const SUCCESS = 'auth/fetch/SUCCESS'
const FAILURE = 'auth/fetch/FAILURE'

export type FetchRequestType = {
  code?: string
}

export const fetch = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<FetchRequestType, boolean, Error>()

const actions = { fetch }
export type FetchAction = ActionType<typeof actions>;

export const fetchEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(fetch.request)),
  mergeMap(action => from(instance.get('auth/user', { params: action.payload })).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      delete response.data.data.code
      delete response.data.data._id
      delete response.data.data.password

      return of(setall(response.data.data), fetch.success(true))
    }),
    catchError(e => of(reset(), resetall(), greset(), fetch.failure(e)))
  ))
)

export default createReducer<RequestState, FetchAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
