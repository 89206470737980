import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Header } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { DeleteForm } from './DeleteForm'
import { ModifyForm } from './ModifyForm'

export const ModifyView: React.FC = () => {
  const history = useHistory()

  const code_saved = useSelector((state: RootState) => state.user.code)

  useEffect(() => {
    if (code_saved === undefined) {
      history.push('/')
    }
  })

  return (
    <div>
      <Header as='h2'>
        회원정보 수정
        <Header.Subheader>
          비밀번호를 수정하거나, 회원 탈퇴를 진행할 수 있습니다.
        </Header.Subheader>
      </Header>

      <Header as='h3' dividing>
        비밀번호 변경
      </Header>
      <ModifyForm />

      <Header as='h3' dividing>
        회원 탈퇴
      </Header>
      <DeleteForm />     
    </div>
  )
}