import assert from "assert"
import { Epic } from "redux-observable"
import { filter, forkJoin, from, mergeMap, of, map, catchError, zip } from "rxjs"
import { ActionType, createAsyncAction, createReducer, isActionOf } from "typesafe-actions"
import { RootAction, RootState } from ".."
import { composeAltCode } from "../../../helper"
import { initialState, instance, RequestState } from "../utils"
import { GyeopgangViewState, set } from "../view/gyeopgang"
import { DeepReadonly } from "utility-types"

type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

type GyeopgangWithoutFree = DeepReadonly<Array<{
  code: string,
  name: string,
  altcode: string,
  sharing: number
}>>
const REQUEST = 'timetable/gyeopgang/REQUEST'
const SUCCESS = 'timetable/gyeopgang/SUCCESS'
const FAILURE = 'timetable/gyeopgang/FAILURE'

export const gyeopgang = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { gyeopgang }
export type GyeopgangAction = ActionType<typeof actions>;

function countIn2DArray(arr: Array<Array<number>>, data: number) {
  var cnt = 0;
  for (const line of arr) {
      for (const block of line) {
          if(block === data) {
              cnt++;
          }
      }
  }
  return cnt;
}

const SIZE: number = 15
export const gyeopgangEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(gyeopgang.request)),
  mergeMap(action => from(instance.get('gyeopgang')).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      let entries: Array<[string, number]> = Object.entries(response.data.data)
      entries = entries.sort(([, l], [, r]) => r - l).slice(0, SIZE)

      return forkJoin(entries.map(([code, sharing]) =>
        from(instance.get('auth/user', { params: { code } })).pipe(
          map(fetchresponse => {
            assert(fetchresponse.data.status === "success")

            let { name, grade, class: class_, number: num }: { name: string, grade: number, class: number, number: number } = fetchresponse.data.data

            return {
              code,
              name,
              altcode: composeAltCode(grade, class_, num),
              sharing
            } as ArrayElement<GyeopgangWithoutFree>
          })
        )
      ))
    })
  )),
  mergeMap(dataarr => {
    return zip(dataarr.map((data) => {
      return from(instance.get(`gyeopgonggang?code=${data.code}`)).pipe(
        map(fetchresponse => {
          assert(fetchresponse.data.status === "success")
          const timearr: Array<Array<number>> = fetchresponse.data.data
          const sharedfree: number = countIn2DArray(timearr, 1)

          return {
            code: data.code,
            name: data.name,
            altcode: data.altcode,
            sharing: data.sharing,
            sharedfree
          } as ArrayElement<GyeopgangViewState>
        }))
      }))
  }),
  mergeMap(data => {
    data = data.sort(({ sharing: l }, { sharing: r }) => r - l);
    return of(set(data), gyeopgang.success(true)).pipe(
      catchError(e => of(gyeopgang.failure(e)))
    )
  })
)

export default createReducer<RequestState, GyeopgangAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
