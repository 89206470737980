import axios from 'axios'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { initialState, RequestState } from '../utils'
import { set } from './view'

const REQUEST = 'snack/fetch/REQUEST'
const SUCCESS = 'snack/fetch/SUCCESS'
const FAILURE = 'snack/fetch/FAILURE'

export const snackfetch = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { snackfetch: snackfetch }
export type SnackFetchAction = ActionType<typeof actions>;

const SNACK_API_URL = "https://snack.sshs-pebble.dev/snack"

export const snackfetchEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(snackfetch.request)),
  mergeMap(action => from(axios.get(SNACK_API_URL)).pipe(
    mergeMap(response => {
      let date = new Date()
      const year = date.getFullYear(); const month = date.getMonth()+1; const day = date.getDate()
      // const year = 2022; const month = 1; const day = 30
      let data;
      let success = false;
      for (data of response.data) {
        if(data.year === year && data.month === month && data.day === day) {
          success = true
          break
        }
      }
      if(!success) return of(snackfetch.failure(Error("Not Found"))) 
      let result = data['snack'].split('\n')
      return of(set({ snack: result }), snackfetch.success(true))
    }),
    catchError(e => of(snackfetch.failure(e)))
  ))
)

export default createReducer<RequestState, SnackFetchAction>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
