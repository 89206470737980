import { combineReducers } from 'redux'
import { StateType } from 'typesafe-actions'
import deleteform, { DeleteformAction } from './deleteform'
import findform, { FindformAction } from './findform'
import loginform, { LoginformAction } from './loginform'
import modifyform, { ModifyformAction } from './modifyform'
import navbar, { NavbarAction } from './navbar'
import patchsubjectform, { PatchsubjectformAction } from './patchsubjectform'
import signupform, { SignupformAction } from './signupform'
import gyeopgang, { GyeopgangViewAction } from './gyeopgang'

const viewReducer = combineReducers({
  loginform,
  signupform,
  modifyform,
  deleteform,
  patchsubjectform,
  findform,
  navbar,
  gyeopgang
})

export default viewReducer
export type ViewState = StateType<typeof viewReducer>
export type ViewAction = DeleteformAction | FindformAction | LoginformAction | ModifyformAction | NavbarAction | PatchsubjectformAction | SignupformAction | GyeopgangViewAction