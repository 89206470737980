import Home from "./pages";
import Find from "./pages/find";
import Login from "./pages/login";
import Modify from "./pages/modify";
// import Signup from "./pages/signup";
import Subject from "./pages/subject";
import { Route } from 'react-router-dom'

function App() {
  return (
    <div>
      <Route path="/" exact component={Home} />
      <Route path="/find" exact component={Find} />
      <Route path="/login" exact component={Login} />
      <Route path="/modify" exact component={Modify} />
      {/*<Route path="/signup" exact component={Signup} />*/}
      {/*<Route path="/subject" exact component={Subject} />*/}
    </div>
  );
}

export default App;
