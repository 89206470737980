import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type PatchsubjectformState = DeepReadonly<{
  action?: "add" | "del"
  subject?: number
  class?: number
  havesubmit: boolean
}>

const initialState: PatchsubjectformState = {
  havesubmit: false
}

const SETACTION = 'view/patchsubjectform/SETACTION'
const SETSUBJECT = 'view/patchsubjectform/SETSUBJECT'
const SETCLASS = 'view/patchsubjectform/SETCLASS'
const SETHAVESUBMIT = 'view/patchsubjectform/SETHAVESUBMIT'
const RESET = 'view/patchsubjectform/RESET'

export const setaction = createAction(SETACTION)<"add" | "del">()
export const setsubject = createAction(SETSUBJECT)<number>()
export const setclass = createAction(SETCLASS)<number>()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const reset = createAction(RESET)()

const actions = { setaction, setsubject, setclass, sethavesubmit, reset }
export type PatchsubjectformAction = ActionType<typeof actions>;

export default createReducer<PatchsubjectformState, PatchsubjectformAction>(initialState, {
  [SETACTION]: (state, action) => Object.assign({}, state, { action: action.payload }),
  [SETSUBJECT]: (state, action) => Object.assign({}, state, { subject: action.payload }),
  [SETCLASS]: (state, action) => Object.assign({}, state, { class: action.payload }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [RESET]: () => initialState
})
