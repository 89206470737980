import React, { useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Form, Button, Input, Message, List } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { modify } from '../redux/reducer/auth/modify'
import { setpassword, setpasscheck, setnewpass, sethavesubmit, reset } from '../redux/reducer/view/modifyform'
import { Requirement } from './Requirement'

export const ModifyForm: React.FC = () => {
  const store = useStore();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const { password, newpass, passcheck, havesubmit } = useSelector((state: RootState) => state.view.modifyform);
  const { loading, success, error } = useSelector((state: RootState) => state.auth.modify)

  const password_p = /.{8,}/

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(sethavesubmit())

    const formelements = event.currentTarget.elements
    for (let i = 0; i < formelements.length; i++) {
      if (!formelements[i].hasAttribute('name')) continue

      const element = formelements[i] as HTMLInputElement
      dispatch({
        type: `view/modifyform/SET${element.name.toUpperCase()}`,
        payload: element.value
      })
    }

    const { password, newpass, passcheck } = store.getState().view.modifyform as RootState['view']['modifyform']

    if (newpass !== passcheck) return

    console.log("Validation Success")

    dispatch(modify.request({ password, newpass }))
  }

  return (
    <div>
      <List relaxed>
        <Requirement header="현재 비밀번호" description="현재 비밀번호를 입력해 주세요." condition={password !== ''} />
        <Requirement header="변경할 비밀번호" description="변경할 비밀번호를 8자 이상으로 설정해 주세요." condition={password_p.test(newpass)} />
        <Requirement header="변경할 비밀번호 확인" description="변경할 비밀번호를 다시 한 번 정확히 입력해 주세요." condition={password_p.test(newpass) && newpass === passcheck} />
      </List>

      <Form onSubmit={onSubmit} success={havesubmit && success} error={havesubmit && error !== undefined}>
        <Form.Field
          control={Input} name="password"
          label="현재 비밀번호" placeholder="현재 비밀번호"
          type="password"
          onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
            dispatch(setpassword(value));
          }}
          error={havesubmit && password === ''}
        />
        <Form.Group widths="equal">
          <Form.Field
            control={Input} name="newpass"
            label="변경할 비밀번호" placeholder="변경할 비밀번호"
            type="password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
              dispatch(setnewpass(value));
            }}
            error={havesubmit && !password_p.test(newpass)}
          />
          <Form.Field
            control={Input} name="passcheck"
            label="변경할 비밀번호 확인" placeholder="변경할 비밀번호 확인"
            type="password"
            onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
              dispatch(setpasscheck(value));
            }}
            error={havesubmit && newpass !== passcheck}
          />
        </Form.Group>
        <Message
          success
          header='비밀번호 변경 성공'
          content='비밀번호를 성공적으로 변경하였습니다.'
        />
        <Message
          error
          header='비밀번호 변경 실패'
          content={error !== undefined ? error!.message : ""}
        />
        <Form.Field>
          <Button fluid type="submit" loading={loading} disabled={
            password === '' || !password_p.test(newpass) || newpass !== passcheck
          }>
            비밀번호 변경
          </Button>
        </Form.Field>
      </Form>
    </div>
  )
}