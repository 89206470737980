import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { StateType } from 'typesafe-actions'
import gethighlight, { GethighlightAction, gethighlightEpic } from './gethighlight'
import getsubjects, { GetsubjectsAction, getsubjectsEpic } from './getsubjects'
import patch, { PatchAction, patchEpic } from './patch'
import update, { UpdateAction, updateEpic } from './update'
import view, { ViewAction } from './view'
import gyeopgang, { GyeopgangAction, gyeopgangEpic } from './gyeopgang'
import find, { FindAction, findEpic } from './find'
import getsharedfreearr, { GetsharedfreearrAction, getsharedfreearrEpic } from './getsharedfree'

const timetableReducer = combineReducers({
  gethighlight,
  getsharedfreearr,
  getsubjects,
  update,
  patch,
  view,
  find,
  gyeopgang
})

export const timetableEpic = combineEpics(
  patchEpic,
  updateEpic,
  gethighlightEpic,
  getsharedfreearrEpic,
  getsubjectsEpic,
  findEpic,
  gyeopgangEpic
)

export default timetableReducer
export type TimeTableState = StateType<typeof timetableReducer>
export type TimeTableAction = GethighlightAction | GetsharedfreearrAction | GetsubjectsAction | PatchAction | UpdateAction | ViewAction | FindAction | GyeopgangAction;