import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type NavbarState = DeepReadonly<{
  activeitem: string
}>

const initialState: NavbarState = {
  activeitem: ""
}

const SETACTIVEITEM = 'view/navbar/SETACTIVEITEM'

export const setactiveitem = createAction(SETACTIVEITEM)<string>()

const actions = {setactiveitem}
export type NavbarAction = ActionType<typeof actions>;

export default createReducer<NavbarState, NavbarAction>(initialState, {
  [SETACTIVEITEM]: (state, action) => Object.assign({}, state, { activeitem: action.payload })
})