import { Epic } from 'redux-observable'
import { catchError, filter, forkJoin, from, map, mergeMap, Observable, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { initialState, instance, RequestState } from '../utils'
import { ClassInfo, Period } from './utils'
import { setclassesinfo, setclasstable, setteachertable, settimetable } from './view'

const REQUEST = 'timetable/update/REQUEST'
const SUCCESS = 'timetable/update/SUCCESS'
const FAILURE = 'timetable/update/FAILURE'

export const update = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { update }
export type UpdateAction = ActionType<typeof actions>;

export const updateEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(update.request)),
  mergeMap(() => {
    const subjectsinfo = state$.value.timetable.view.subjectsinfo

    const { subjects, classes } = state$.value.user
    const zipped = subjects!.map((subject, i) => {
      return { subject: subject, class: classes![i] };
    })

    let classesinfo = state$.value.timetable.view.classesinfo

    classesinfo = classesinfo.filter(elem => {
      return zipped.find(({ subject, class: class_ }) => elem.subject === subject && elem.class === class_) !== undefined
    })

    let appendinfoObs: Observable<ClassInfo>[] = []

    for (const { subject, class: class_ } of zipped) {
      if (classesinfo.find(elem => elem.subject === subject && elem.class === class_) === undefined) {
        appendinfoObs.push(from(instance.get(`subjects/${subject}/${class_}`)).pipe(
          map(response => ({
            name: subjectsinfo.find(elem => elem.code === subject)!.name,
            subject: subject,
            class: class_,
            periods: response.data.data.info as Period[]
          })
          )
        ))
      }
    }

    if(appendinfoObs.length === 0) return of(classesinfo)

    return forkJoin(appendinfoObs).pipe(
      map(appendinfo => [...classesinfo, ...appendinfo])
    )
  }),
  map(classesinfo => {
    let timetable: string[][][] = [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]]
    let classtable: number[][][] = [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]]
    let teachertable: string[][][] = [[[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []], [[], [], [], [], [], [], []]]

    classesinfo.forEach(({ name, class: class_, periods }) => {
      periods.forEach(({ day, time, teacher }) => {
        timetable[day][time - 1].push(name)
        classtable[day][time - 1].push(class_)
        teachertable[day][time - 1].push(teacher)
      })
    })

    return { classesinfo, timetable, classtable, teachertable }
  }),
  mergeMap(({ classesinfo, timetable, classtable, teachertable }) => of(
    setclassesinfo(classesinfo),
    settimetable(timetable),
    setclasstable(classtable),
    setteachertable(teachertable),
    update.success(true)).pipe(
      catchError(e => of(update.failure(e)))
    ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
