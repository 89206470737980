import React, { useEffect } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { useHistory } from 'react-router'
// import { Link } from 'react-router-dom'
import { Button, Form, Icon, Input, Message } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { login } from '../redux/reducer/auth/login'
import { reset, setcode, sethavesubmit, setpassword } from '../redux/reducer/view/loginform'


const code_p = /[1-2]\d[0-1]\d{2}/

export const LoginForm: React.FC = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const history = useHistory();

  const code_saved = useSelector((state: RootState) => state.user.code)

  useEffect(() => {
    dispatch(reset())
  }, [dispatch])

  const { havesubmit } = useSelector((state: RootState) => state.view.loginform)
  const { loading, success, error } = useSelector((state: RootState) => state.auth.login)

  useEffect(() => {
    if (code_saved !== undefined && success) {
      history.push('/')
    }
  })

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(sethavesubmit())

    const formelements = event.currentTarget.elements
    for (let i = 0; i < formelements.length; i++) {
      if (!formelements[i].hasAttribute('name')) continue

      const element = formelements[i] as HTMLInputElement
      dispatch({
        type: `view/loginform/SET${element.name.toUpperCase()}`,
        payload: element.value
      })
    }

    const { code, password } = store.getState().view.loginform as RootState['view']['loginform']
    if (!code_p.test(code)) return;

    console.log("Validation Success")

    dispatch(login.request({ code, password }))
  }

  return (
    <Form onSubmit={onSubmit} error={havesubmit && error !== undefined}>
      <Form.Field
        control={Input} name="code"
        label="교번" placeholder="교번"
        type="text" maxLength={5}
        onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
          dispatch(setcode(value))
        }}
      />
      <Form.Field
        control={Input} name="password"
        label="비밀번호" placeholder="비밀번호"
        type="password"
        onChange={(event: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
          dispatch(setpassword(value))
        }}
      />
      <Message
        error
        header='로그인 실패'
        content={error !== undefined ? error!.message : ""}
      />
      <Button fluid type="submit" loading={loading}>
        <Icon name="sign-in" />
        로그인
      </Button>
      {/*
      <div className="w-full bg-transparent text-center mt-7">
        <Link to="/signup" className="gyg link">
        회원가입하기
        </Link>
      </div>
      */}
    </Form>
  )
}