import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, filter, from, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { resetall } from '../timetable/view'
import { reset } from '../user'
import { initialState, instance, RequestState } from '../utils'
import { greset } from '../view/gyeopgang'

const REQUEST = 'auth/logout/REQUEST'
const SUCCESS = 'auth/logout/SUCCESS'
const FAILURE = 'auth/logout/FAILURE'

export const logout = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<undefined, boolean, Error>()

const actions = { logout }
export type LogoutAction = ActionType<typeof actions>;

export const logoutEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(logout.request)),
  mergeMap(action => from(instance.post('auth/logout')).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      return of(reset(), resetall(), greset(), logout.success(true))
    }),
    catchError(e => of(logout.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
