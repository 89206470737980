import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

export type MealViewState = DeepReadonly<{
  morning?: string[]
  lunch?: string[]
  dinner?: string[]
}>

const SET = 'meal/view/SET'

export const set = createAction(SET)<MealViewState>()

const actions = { set }
export type MealViewAction = ActionType<typeof actions>;

export default createReducer<MealViewState, MealViewAction>({}, {
  [SET]: (state, action) => action.payload
})
