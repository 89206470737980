import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HomeView } from '../components/HomeView';
import { withBasicView } from '../components/withBasicView';
import { setactiveitem } from '../redux/reducer/view/navbar';

export default function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setactiveitem('Home'))
  }, [dispatch])

  const HomeWithBasicView = withBasicView(HomeView);

  return <HomeWithBasicView />;
}