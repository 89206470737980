import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Loader, Table } from 'semantic-ui-react'
import { RootState } from '../redux/reducer'
import { update } from '../redux/reducer/timetable/update'
import { resethighlight } from '../redux/reducer/timetable/view'

interface TimeTableProps {
  highlight?: boolean
  sharedfreearr?: boolean
}

export const TimeTable: React.FC<TimeTableProps> = (props: TimeTableProps) => {
  const dispatch = useDispatch()
  const { timetable, classtable, teachertable, highlight, sharedfreearr, altcode, name } = useSelector((state: RootState) => state.timetable.view)
  const { loading, success } = useSelector((state: RootState) => state.timetable.update)

  useEffect(() => {
    dispatch(update.request())
  }, [dispatch])

  if (loading || !success) {
    return <Loader className="pb-5" active inline='centered' content="시간표 정보를 가져오고 있습니다." />
  }

  return (
    <Table fixed celled unstackable textAlign="center" style={{ position: 'static' }}>
      <Table.Header>
        {altcode !== undefined && name !== undefined ?
          <Table.Row>
            <Table.HeaderCell colSpan='5' textAlign="left">
              {altcode} {name}님과의 겹강:
            </Table.HeaderCell>
          </Table.Row> :
          <Table.Row>
            <Table.HeaderCell>월</Table.HeaderCell>
            <Table.HeaderCell>화</Table.HeaderCell>
            <Table.HeaderCell>수</Table.HeaderCell>
            <Table.HeaderCell>목</Table.HeaderCell>
            <Table.HeaderCell>금</Table.HeaderCell>
          </Table.Row>
        }
      </Table.Header>

      <Table.Body>
        {[0, 1, 2, 3, 4, 5, 6].map((col) =>
          <Table.Row key={`col-${col}`}>
            {[0, 1, 2, 3, 4].map((row) =>
              <Table.Cell key={`cell-${col}-${row}`}
                style={{ wordBreak: 'break-all' }}
                warning={timetable[row][col].length === 0 &&
                  props.sharedfreearr !== undefined && props.sharedfreearr! &&
                  sharedfreearr[row][col]
                }
                disabled={timetable[row][col].length === 0 && (
                  props.sharedfreearr === undefined || !(props.sharedfreearr!) || !(sharedfreearr[row][col])
                )}
                negative={timetable[row][col].length === 2}
                positive={
                  timetable[row][col].length === 1 &&
                  props.highlight !== undefined && props.highlight! &&
                  highlight[row][col]
                }
              >
                {timetable[row][col].length === 0
                  ? "(공강)"
                  : timetable[row][col].length >= 2
                    ? "(중복)"
                    :
                    <div>
                      <div className="font-semibold">{timetable[row][col]}</div>
                      <div className="text-xs">{classtable[row][col]}분반</div>
                      <div className="text-xs">{teachertable[row][col]}</div>
                    </div>
                }
              </Table.Cell>
            )}
          </Table.Row>
        )}
      </Table.Body>

      {(altcode !== undefined && name !== undefined) &&
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              <Button floated='right' onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                dispatch(resethighlight())
              }}>
                리셋
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      }
    </Table>
  )
}