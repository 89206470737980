import assert from 'assert'
import { Epic } from 'redux-observable'
import { catchError, concat, filter, first, from, mapTo, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { fetch } from '../auth/fetch'
import { initialState, instance, RequestState } from '../utils'
import { update } from './update'

const REQUEST = 'timetable/patch/REQUEST'
const SUCCESS = 'timetable/patch/SUCCESS'
const FAILURE = 'timetable/patch/FAILURE'

export type PatchRequestType = {
  action: "add" | "del",
  subject: number,
  class: number
}

export const patch = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<PatchRequestType, boolean, Error>()

const actions = { patch }
export type PatchAction = ActionType<typeof actions>;

export const patchEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(patch.request)),
  mergeMap(action => from(instance.patch('auth/user', action.payload)).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      return concat(
        of(fetch.request({ code: state$.value.user.code!})),
        action$.pipe(filter(isActionOf(fetch.success)), first(), mapTo(update.request())),
        action$.pipe(filter(isActionOf(update.success)), first(), mapTo(patch.success(true)))
      )
    }),
    catchError(e => of(patch.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})