import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

type ModifyformState = DeepReadonly<{
  password: string
  newpass: string
  passcheck: string
  havesubmit: boolean
}>

const initialState: ModifyformState = {
  password: "",
  newpass: "",
  passcheck: "",
  havesubmit: false
}

const SETPASSWORD = 'view/modifyform/SETPASSWORD'
const SETNEWPASS = 'view/modifyform/SETNEWPASS'
const SETPASSCHECK = 'view/modifyform/SETPASSCHECK'
const SETHAVESUBMIT = 'view/modifyform/SETHAVESUBMIT'
const RESET = 'view/modifyform/RESET'

export const setpassword = createAction(SETPASSWORD)<string>()
export const setnewpass = createAction(SETNEWPASS)<string>()
export const setpasscheck = createAction(SETPASSCHECK)<string>()
export const sethavesubmit = createAction(SETHAVESUBMIT)()
export const reset = createAction(RESET)()

const actions = { setpassword, setnewpass, setpasscheck, sethavesubmit, reset }
export type ModifyformAction = ActionType<typeof actions>;

export default createReducer<ModifyformState, ModifyformAction>(initialState, {
  [SETPASSWORD]: (state, action) => Object.assign({}, state, { password: action.payload }),
  [SETNEWPASS]: (state, action) => Object.assign({}, state, { newpass: action.payload }),
  [SETPASSCHECK]: (state, action) => Object.assign({}, state, { passcheck: action.payload }),
  [SETHAVESUBMIT]: (state) => Object.assign({}, state, { havesubmit: true }),
  [RESET]: () => initialState
})
