import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { DeepReadonly } from 'utility-types'

export type GyeopgangViewState = DeepReadonly<Array<{
  code: string,
  name: string,
  altcode: string,
  sharing: number,
  sharedfree: number
}>>

const SET = 'view/gyeopgang/SET'
const RESET = 'view/gyeopgang/RESET'

export const set = createAction(SET)<GyeopgangViewState>()
export const greset = createAction(RESET)()

const actions = { set, greset }
export type GyeopgangViewAction = ActionType<typeof actions>;

export default createReducer<GyeopgangViewState, GyeopgangViewAction>([], {
  [SET]: (state, action) => action.payload,
  [RESET]: () => []
})
