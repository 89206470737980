import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { StateType } from 'typesafe-actions'
import view, { SnackViewAction } from './view'
import fetch, { SnackFetchAction, snackfetchEpic } from './fetch'

const snackReducer = combineReducers({
  view,
  fetch
})

export const snackEpic = combineEpics(
  snackfetchEpic
)

export default snackReducer
export type SnackState = StateType<typeof snackReducer>
export type SnackAction = SnackFetchAction | SnackViewAction