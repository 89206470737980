import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FindView } from '../components/FindView';
import { withBasicView } from '../components/withBasicView';
import { setactiveitem } from '../redux/reducer/view/navbar';

export default function Find() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setactiveitem('Find'))
  }, [dispatch])
  
  const HomeWithBasicView = withBasicView(FindView);

  return <HomeWithBasicView />;
}