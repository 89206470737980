import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { StateType } from 'typesafe-actions'
import delete_, { DeleteAction, deleteEpic } from './delete'
import fetch, { FetchAction, fetchEpic } from './fetch'
import login, { LoginAction, loginEpic } from './login'
import logout, { LogoutAction, logoutEpic } from './logout'
import modify, { ModifyAction, modifyEpic } from './modify'
import signup, { SignupAction, signupEpic } from './signup'

const authReducer = combineReducers({
  login,
  logout,
  signup,
  modify,
  delete: delete_,
  fetch
})

export const authEpic = combineEpics(
  fetchEpic,
  deleteEpic,
  loginEpic,
  logoutEpic,
  modifyEpic,
  signupEpic
)

export default authReducer
export type AuthState = StateType<typeof authReducer>
export type AuthAction = DeleteAction | FetchAction | LoginAction | LogoutAction | ModifyAction | SignupAction;