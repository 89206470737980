import assert from 'assert'
import SHA384 from 'crypto-js/sha384'
import { Epic } from 'redux-observable'
import { catchError, filter, from, map, mergeMap, of } from 'rxjs'
import { ActionType, createAsyncAction, createReducer, isActionOf } from 'typesafe-actions'
import { RootAction, RootState } from '..'
import { setcode } from '../user'
import { initialState, instance, RequestState } from '../utils'

const REQUEST = 'auth/login/REQUEST'
const SUCCESS = 'auth/login/SUCCESS'
const FAILURE = 'auth/login/FAILURE'

export type LoginRequestType = {
  code: string,
  password: string
}

export const login = createAsyncAction(
  REQUEST, SUCCESS, FAILURE
)<LoginRequestType, boolean, Error>()

const actions = { login }
export type LoginAction = ActionType<typeof actions>;

export const loginEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  state$
) => action$.pipe(
  filter(isActionOf(login.request)),
  map(action => {
    // console.log("asdf")
    return action
  }),
  map(action => ({
    ...action.payload,
    password: SHA384(action.payload.password).toString()
  })),
  mergeMap(payload => from(instance.post('auth/login', payload)).pipe(
    mergeMap(response => {
      assert(response.data.status === "success")

      return of(setcode(payload.code), login.success(true))
    }),
    catchError(e => of(login.failure(e)))
  ))
)

export default createReducer<RequestState, ActionType<typeof actions>>(initialState, {
  [REQUEST]: (state) => Object.assign({}, state, {
    loading: true,
    error: undefined
  }),
  [SUCCESS]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: action.payload,
    error: undefined
  }),
  [FAILURE]: (state, action) => Object.assign({}, state, {
    loading: false,
    success: false,
    error: action.payload
  })
})
