import axios from 'axios'
import { DeepReadonly } from 'utility-types'

export const instance = axios.create({
  withCredentials: true,
  baseURL: "https://api.sshs-pebble.dev/gyeopgang"
})

export type RequestState = DeepReadonly<{
  loading: boolean
  success: boolean
  error?: Error
}>

export const initialState: RequestState = {
  loading: false,
  success: false
}